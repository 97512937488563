import axios from "axios";

/**
   * @description Get authenticated user, basic personal details by user Id
   * @returns {any} object of person, address, companies
 */
export const GetPersonDetails = async (id: any) => {
    return await axios.get(`person/${id}`).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
       return error;
    });
}

/**
   * @description Get authenticated user, person name by user Id
   * @returns {String} user holder name
 */
export const GetAccountHolderName = async (id: any) => {
    return await axios.get(`person/${id}/name/`).then(function (response: any) {
        return response;
    }).catch(function (error: any) {
       return error;
    });
}

/**
   * @description Update a persons basic details and if exists payment provider details
   * @returns {String} API response message
 */
 export const UpdatePersonDetails = async (data: any) => {
    return await axios.post(`placenta/person/update/`, data);
}