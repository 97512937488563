/** @jsx jsx */
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import { useEffect, useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { Button, jsx } from "theme-ui";
import * as Yup from "yup";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";
import { getJwtTokenIdentifier } from "../../services/auth-service";
import {
  GetPersonDetails,
  UpdatePersonDetails,
} from "../../services/person-service";
import { SendError } from "../../services/error-log-service";

const AccountDetailsPage: React.FC<any> = () => {
  const [formLoading, setFormLoading] = useState(true);
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [addresses, setAddressess] = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    await GetPersonDetails(getJwtTokenIdentifier()).then(({ data }) => {
      if (data !== undefined) {
        setData(data);
        if (data?.companies?.length > 0) {
          setCompanies(data.companies);
        }

        if (data?.addresses?.length > 0) {
          setAddressess(
            data.addresses.filter(
              (x: { companyId: null }) => x.companyId == null
            )
          );
        }
        setFormLoading(false);
      }
    });
  };

  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="container" sx={styles.container}>
        <div sx={styles.wrapper} className="col-md-8">
          <UserDetailsForm
            data={data}
            companies={companies}
            addresses={addresses}
            formLoading={formLoading}
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

const UserDetailsForm = (props: any) => {
  const data = props.data;
  const formLoading = props.formLoading;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    surname: Yup.string().required("Last name is required"),
  });
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Formik
        initialValues={{
          firstName: data.firstName ? data.firstName : "",
          surname: data.surname ? data.surname : "",
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setLoading(true);
          setError(false);
          setMessage("");

          if (data === undefined) {
            setLoading(false);
            setMessage("Something went wrong, please try again.");
          }

          await UpdatePersonDetails({
            firstName: values.firstName,
            surname: values.surname,
            phone: null,
            addresses: null,
            companies: null,
          })
            .then(async ({ data }) => {
              setError(false);
              setLoading(false);
              setMessage(data);
            })
            .catch((error) => {
              SendError(error);
              setLoading(false);
              setError(true);
              setMessage(error.response.data.message);
            });
        }}
      >
        {(formik) => {
          return (
            <Form id="AccountDetailsForm">
              <h1 sx={styles.header}>My Details</h1>
              <hr className="bar-indent-center" />
              {!formLoading ? (
                <Fragment>
                  <div sx={styles.form.details} className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="firstName" sx={styles.form.label}>
                          First Name
                        </label>
                        <Field
                          name="firstName"
                          sx={styles.form.input}
                          id="firstName"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          sx={styles.input.error}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="surname" sx={styles.form.label}>
                          Surname
                        </label>
                        <Field
                          name="surname"
                          sx={styles.form.input}
                          id="surname"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="surname"
                          component="div"
                          sx={styles.input.error}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    {message !== "" && (
                      <p
                        className={!error ? "text-success" : "text-danger"}
                        sx={styles.message}
                      >
                        {message}
                      </p>
                    )}

                    <Button
                      sx={styles.button}
                      id="submitUserDetails"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {!loading && <span>Update Details</span>}

                      {loading && (
                        <Spinner animation="border" role="status">
                          <span className="sr-only"></span>
                        </Spinner>
                      )}
                    </Button>
                  </div>
                </Fragment>
              ) : (
                <span sx={styles.spinner}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </span>
              )}
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

const styles = {
  container: {
    paddingLeft: "0!important",
    paddingRight: "0!important",
  },
  wrapper: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    padding: "30px 50px",
    borderRadius: "6px",
    background: "white",
    maxWidth: "1050px",
    margin: "30px auto",
    position: "relative",
    "@media only screen and (max-width: 536px)": {
      padding: "30px 10px",
    },
  },
  header: {
    textAlign: "center",
  },
  additonal: {
    fontSize: "32px",
    fontWeight: "600",
  },
  button: {
    marginTop: "30px",
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "2em",
    background: "#00d4c5",
    width: "100%",
    textTransform: "uppercase",
  },
  input: {
    error: {
      fontSize: "13px",
      paddingBottom: "5px",
      color: "#dc3545",
    },
  },
  form: {
    details: {
      marginTop: 30,
    },
    label: {
      fontWeight: "600",
      textTransform: "uppercase",
      marginBottom: "0.5em",
      color: "#2F3E57",
    },
    input: {
      width: "100%",
      fontSize: "14px",
      fontWight: "400",
      borderRadius: 6,
      padding: "10px",
      opacity: ".7",
      marginBottom: "10px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      error: {
        fontSize: "13px",
        marginTop: "-5px",
        paddingBottom: "5px",
        color: "#dc3545",
      },
    },
  },
  error: {
    textAlign: "center",
    fontSize: "16px",
    fail: {
      color: "#dc3545",
    },

    success: {
      color: "#6da373",
    },
  },
  spinner: {
    marginTop: "40px",
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
};

export default AccountDetailsPage;
