/** @jsx jsx */
import { Link } from 'gatsby';
import { FC } from 'react';
import { Box, jsx } from 'theme-ui';
import styles from './account.style';

const AccountTopMenu: FC<any> = () => {
    return (
        <Box sx={styles.topMenu}>
            <Box sx={styles.mainMenu} className="mainMenu" as="ul">
                <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account" activeClassName="active">
                        Dashboard
                    </Link>
                </Box>
                <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account/details" activeClassName="active">
                        My Details
                    </Link>
                </Box>
                <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account/email" activeClassName="active">
                        My Email
                    </Link>
                </Box>
                <Box as="li" sx={styles.mainMenuItem}>
                    <Link to="/account/password" activeClassName="active">
                        My Password
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountTopMenu
