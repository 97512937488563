const styles = {
	topMenu: {
		// top: -1,
		backgroundColor: '#F5F5F5',
		height: 'auto',
		textAlign: 'center',
		lineHeight: '52px',
		listStyle: 'none',
		borderColor:'red'
	},
	mainMenu: {
		display: 'flex',
		position: 'absolute',
		left: '50%',
		transform: 'translatex(-50%)',
		padding: 0,
		'@media only screen and (max-width: 1080px)': {
			transform: 'unset',
			position: 'unset',
			margin: '0 auto',
			padding: 'initial',
			display: 'inline-flex'
		},
		li: {
			color: 'primary',
			listStyle: 'none',
			'a, span': {
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				textDecoration: 'none',
				transition: 'color 0.2s ease',
				'@media only screen and (max-width: 1080px)': {
					marginLeft: 0
				}
			},
		},
	},
	mainMenuItem: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		marginX: 16,
		'@media only screen and (max-width: 1080px)': {
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
		'> a, > span': {
			color: 'primary',
			fontSize: 1,
			lineHeight: '63px',
			minHeight: [50, 52],
			'&.active': {
				fontWeight: '600',
				color: '#685f52'
			},
		},
		'> svg': {
			marginX: '5px',
			'@media only screen and (max-width: 1080px)': {
				position: 'absolute',
				top: '25px',
				right: 0,
			},
		},
		':hover': {
			color: 'primary',
			'> a, > span': {
				color: 'primary',
				'&.active': {
					fontWeight: '600',
					color: 'black'
				},
			},
			ul: {
				opacity: 1,
				visibility: 'visible',
				'@media only screen and (max-width: 1080px)': {
					opacity: 0,
					visibility: 'hidden',
					'&.active': {
						opacity: 1,
						visibility: 'visible',
					},
				},
				li: {
					color: 'primary',
					marginY: 0,
				},
			},
		}
	}
};

export default styles;
